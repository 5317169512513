<template>
    <div>
        <label class="vut-h4 mb-3 block" :for="voucherInputId">
            {{ $t('cart.voucher_title') }}
        </label>

        <CoreUiForm v-slot="{ formData, isFormSubmitting, formFieldErrors }"
                    :form="form"
                    class="mb-7 w-full"
        >
            <!-- TODO [BaseUiElementGroup]: add support for vertical orientation https://github.com/simplo-sro/simploshop-fe/issues/563 -->
            <BaseUiElementGroup class="flex-col gap-3 sm:flex-row sm:gap-0">
                <BaseUiFormInput
                    :id="voucherInputId"
                    v-model:form="formData.code"
                    :placeholder="$t('labels.enter_voucher_code')"
                    :error-id="errorId"
                />

                <BaseUiButton
                    color="secondary"
                    type="submit"
                    :loading="isFormSubmitting"
                    class="flex-none"
                >
                    {{ $t('labels.apply') }}
                </BaseUiButton>
            </BaseUiElementGroup>

            <BaseUiFormError
                v-if="formFieldErrors.code"
                :message="formFieldErrors.code"
                :error-id="errorId"
            />
        </CoreUiForm>

        <div v-for="discount in cart?.discounts"
             :key="discount.id!"
             class="vut-h6 vut-voucher mb-4 last:mb-7"
        >
            <IconVoucher height="15" />

            {{ discount.name }}

            <BaseUiButton
                size="auto"
                type="button"
                color="none"
                class="!-m-2 !p-2"
                @click="removeVoucher(discount.id!)"
            >
                <IconCross :size="10" />
            </BaseUiButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import { z } from 'zod'

const voucherInputId = useId()
const errorId = useId()

const { applyVoucher, removeVoucher } = useCart()

const schema = z.object({
    code: z.string(),
})

const form = useForm({
    schema: schema,
    onSubmit: (data) => applyVoucher(data.code),
    resetOn: 'submit',
})

const { cart } = useCart()
</script>

<style lang="scss" scoped>
.vut-voucher {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    padding: 0.5rem 0.625rem;

    width: fit-content;

    color: $vut-c-white;
    background: $vut-c-gray;

    border-radius: $vut-border-radius;
}
</style>
