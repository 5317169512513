<template>
    <div class="vut-summary"
         :class="{ 'vut-summary--full': fullInfo,
                   'vut-container-loading': loading }"
    >
        <template v-if="fullInfo">
            <div v-for="item in items"
                 :key="item.id!"
                 class="vut-summary__item"
            >
                <div class="flex max-w-64 flex-1 items-start gap-4">
                    <div class="vut-summary__image-box">
                        <CoreImg
                            :src="item.imageUrl"
                            alt=""
                            loading="lazy"
                            :width="64"
                            class="vut-summary__image-el"
                        />
                    </div>

                    <div>
                        <span class="vut-ff-secondary">
                            {{ item.name }}
                        </span>

                        <div v-for="(value, key) in item.getValuesOfVariationProperties()"
                             :key="key"
                             class="vut-text-small font-normal !leading-tight"
                        >
                            {{ key.toString().toLowerCase() }}:
                            <span class="font-bold">
                                {{ value }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="vut-ff-secondary vut-text-large flex w-full items-end justify-between gap-2 sm:w-auto sm:flex-col">
                    {{ getFormattedPrice(item?.taxedTotalPrice) }}

                    <UiCounter :value="item.amount"
                               counter-class="!h-8"
                               input-class="!w-8"
                               :max-value="item.stockState"
                               @update="(value: number) => changeItemQuantity(item.id!, value)"
                    />
                </div>
            </div>
        </template>
        <div v-else class="vut-summary__block vut-summary__title">
            <span>
                {{ $t('cart.total_price_without_tax') }}
            </span>
            <span>
                {{ getFormattedPrice(cart?.itemsPrice) }}
            </span>
        </div>

        <div v-if="cart?.shippingMethod && fullInfo" class="vut-summary__block">
            <div>
                <div class="vut-summary__title">
                    {{ $t('labels.shipping') }}
                </div>

                <div class="mt-2">
                    <div class="vut-ff-secondary">
                        {{ cart?.shippingMethod?.name }}
                    </div>

                    <div v-if="cart?.shippingMethod?.description || cart?.shippingOption?.name" class="text-gray leading-5">
                        {{ cart?.shippingMethod?.description || cart?.shippingOption?.name }}
                    </div>
                </div>
            </div>

            <span class="vut-summary__title">
                {{ getFormattedPrice(cart?.shippingMethod?.taxedPrice) }}
            </span>
        </div>

        <div v-if="cart?.paymentMethod && fullInfo" class="vut-summary__block">
            <div>
                <div class="vut-summary__title">
                    {{ $t('labels.payment') }}
                </div>

                <div class="text-gray">
                    {{ cart?.paymentMethod?.name }}
                </div>
            </div>

            <span class="vut-summary__title">
                {{ getFormattedPrice(cart?.paymentMethod?.taxedPrice) }}
            </span>
        </div>

        <div v-if="cart?.discounts?.length" class="vut-summary__block flex flex-col">
            <div v-for="discount in cart?.discounts"
                 :key="discount.id!"
                 class="flex justify-between gap-4"
            >
                <div>
                    <span class="vut-summary__title">
                        {{ discount.name }}
                    </span>

                    <span :class="fullInfo ? 'block text-gray' : 'pl-1 vut-summary__title'">
                        {{ `(${discount.code})` }}
                    </span>
                </div>

                <div class="vut-summary__title">
                    {{ getFormattedPrice(discount.taxedAmount) }}
                </div>
            </div>
        </div>

        <template v-if="!fullInfo">
            <!-- TODO: move to model https://github.com/simplo-sro/simploshop-fe/issues/628 -->
            <div v-for="rate in Object.keys(cart?.taxPerRate ?? {}).map(Number)"
                 :key="rate"
                 class="vut-summary__block vut-summary__title"
            >
                <span>
                    {{ $t('cart.tax', [rate]) }}
                </span>
                <span>
                    {{ getFormattedPrice(cart?.getAmountPerTaxRate(rate)) }}
                </span>
            </div>
        </template>

        <hr v-if="!fullInfo" class="m-0">

        <div class="vut-summary__block vut-h4">
            <span>
                {{ $t('cart.total_price_with_tax') }}
            </span>
            <span>
                {{ getFormattedPrice(cart?.taxedTotalPrice) }}
            </span>
        </div>
    </div>
</template>

<script lang="ts" setup>
const { cart, items, changeItemQuantity } = useCart()

const {
    fullInfo,
    loading,
} = defineProps<{
    fullInfo?: boolean
    loading?: boolean
}>()

</script>

<style lang="scss" scoped>
.vut-summary {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    padding-bottom: 1.25rem;
}

.vut-summary--full {
    border: hr-line();
    gap: 0;

    padding-bottom: 0;
}

.vut-summary__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem 2rem;

    padding: 1.25rem;
    background: $vut-c-ivory;
    border-bottom: hr-line();

    font-size: 1rem;
    line-height: 1.25rem;

    @include more-than(sm) {
        flex-direction: row;
    }
}

.vut-summary__image-box {
    flex: none;
    padding: 0.125rem;
    width: 4rem;

    border: hr-line();
    border-radius: $vut-border-radius;
}

.vut-summary__image-el {
    aspect-ratio: 3 / 2;
    object-fit: cover;

    height: 100%;
    width: 100%;
}

.vut-summary__block {
    display: flex;
    justify-content: space-between;
    gap: 1rem 2rem;
}

.vut-summary--full .vut-summary__block {
    padding: 1.25rem;

    &:not(:last-child) {
        border-bottom: hr-line();
    }
}

.vut-summary__title {
    font-family: $vut-ff-secondary;
    font-size: 1rem;
    line-height: 1.25rem;
}

.vut-summary--full .vut-summary__title {
    line-height: 1.25rem;
    font-size: 1.125rem;
}
</style>
