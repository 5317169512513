<template>
    <NuxtLayout name="default">
        <!--  HEADER SECTION  -->
        <PageNavbar v-if="isCartLayoutVisible && !showNoItemsMessage"
                    :items="visiblePhases.map(p => ({
                        routeName: String(p.routeName),
                        labelPath: p.labelPath ?? '',
                        disabled: !canSwitchTo(p),
                    }))"
                    type="fill"
        />

        <BaseContainerContent
            class="vut-l-cart"
            :padding="{
                top: !isCartLayoutVisible ? 'large' : undefined,
                bottom: 'large',
            }"
        >
            <!--  MAIN CONTENT  -->
            <div :class="isCartSummaryVisible ? 'flex-1' : 'w-full'">
                <slot />

                <BaseUiButton v-if="!showNoItemsMessage && !isPhaseActive(CartPhase.DETAIL) && !isPhaseActive(CartPhase.SUMMARY)"
                              size="large"
                              color="secondary"
                              variant="bare"
                              class="hidden w-fit !px-0 lg:block"
                              :to="localePath({ name: previousPhase?.routeName })"
                >
                    <template #leading>
                        <IconChevron :size="15" rotate="right" />
                    </template>

                    {{ previousPhase?.id ? backToPhaseLabels[previousPhase.id] : $t('cart.back_to.catalog') }}
                </BaseUiButton>
            </div>

            <template v-if="!showNoItemsMessage">
                <div v-if="isPhaseActive(CartPhase.DETAIL)"
                     class="flex flex-col items-start justify-between"
                >
                    <CartVoucher class="w-full" />

                    <BaseUiButton size="large"
                                  color="secondary"
                                  variant="bare-aligned"
                                  class="hidden lg:block"
                                  :to="localePath('/')"
                    >
                        <template #leading>
                            <IconChevron :size="15" rotate="right" />
                        </template>

                        {{ previousPhase?.id ? backToPhaseLabels[previousPhase.id] : $t('cart.back_to.catalog') }}
                    </BaseUiButton>
                </div>

                <div>
                    <!--  SUMMARY  -->
                    <CartSummary v-if="cart && items?.length && !isPhaseActive(CartPhase.SUMMARY)"
                                 class="mb-5 lg:min-w-96"
                                 :full-info="!isPhaseActive(CartPhase.DETAIL)"
                                 :loading="isCartLoading || isPaymentMethodLoading || isShippingMethodLoading"
                    />

                    <!--  BUTTON  -->
                    <CoreUiForm v-if="isLastCartPhase"
                                v-slot="{ formData }"
                                ref="formEl"
                                :form="form"
                                class="px-4"
                    >
                        <CoreUiFormRow>
                            <BaseUiFormCheckbox
                                v-model:form="formData.agree_terms_and_conditions"
                                hide-required
                            >
                                <i18n-t scope="global" keypath="cart.agree_terms_and_conditions">
                                    <template #link>
                                        <NuxtLink :to="getStaticPageUrl('terms')" class="underline hover:no-underline" target="_blank">
                                            {{ $t('cart.agree_terms_and_conditions_link') }}
                                        </NuxtLink>
                                    </template>
                                </i18n-t>
                            </BaseUiFormCheckbox>
                        </CoreUiFormRow>

                        <CoreUiFormRow>
                            <BaseUiFormCheckbox
                                v-model:form="formData.gdpr"
                                hide-required
                            >
                                <i18n-t scope="global" keypath="cart.agree_gdpr">
                                    <template #link>
                                        <NuxtLink :to="getStaticPageUrl('gdpr')" class="underline hover:no-underline" target="_blank">
                                            {{ $t('cart.agree_gdpr_link') }}
                                        </NuxtLink>
                                    </template>
                                </i18n-t>
                            </BaseUiFormCheckbox>
                        </CoreUiFormRow>
                    </CoreUiForm>

                    <BaseUiButton
                        v-if="isCartLayoutVisible"
                        color="primary"
                        size="large"
                        class="w-full"
                        :on-click="isLastCartPhase ? submitCartOrder : undefined"
                        :to="isLastCartPhase ? undefined : localePath({ name: nextPhase?.routeName })"
                        :loading="isCartLoading || isPaymentMethodLoading || isShippingMethodLoading"
                        :disabled="isLastCartPhase ? undefined : !canSwitchTo(nextPhase)"
                    >
                        {{ continueToPhaseLabels[nextPhase!.id] }}
                    </BaseUiButton>

                    <BaseUiFormError v-if="errorMessage && canErrorMessageBeShown" class="mt-2">
                        {{ errorMessage }}
                    </BaseUiFormError>
                </div>
            </template>
        </BaseContainerContent>
    </NuxtLayout>
</template>

<script lang="ts" setup>
import type { CartPhases } from '@core-theme/types/cart'
import { CoreUiForm } from '#components'

const localePath = useLocalePath()

const { cart, items, fetchItems, phase, visiblePhases, isPhaseActive, previousPhase, nextPhase, canSwitchTo, submitOrder, isLastCartPhase, isCartLoading, isShippingMethodLoading, isPaymentMethodLoading } = useCart()

const isCartLayoutVisible = computed<boolean>(() => !isPhaseActive(CartPhase.SUMMARY))
const showNoItemsMessage = computed<boolean>(() => !items.value?.length)
const isCartSummaryVisible = computed<boolean>(() => ![CartPhase.DETAIL, CartPhase.SUMMARY].includes(phase.value?.id as any))
const canErrorMessageBeShown = computed<boolean>(() => isPhaseActive(CartPhase.DELIVERY))

const errorMessage = ref<string | null>(null)

const { t } = useI18n()
const { getStaticPageUrl } = useStaticPages()

const { notifyError } = useNotifications()

async function submitCartOrder() {
    errorMessage.value = null

    try {
        await submitOrder({
            onSuccess: async () => {
                await navigateTo(localePath({ name: 'cart-summary' }))
            },
        })
    } catch (e) {
        if (e instanceof ApiResponseError) {
            errorMessage.value = e.getErrorMessage()
        }

        notifyError(t('messages.error.order_submit'))

        console.error(e)
    }
}

const continueToPhaseLabels: Record<keyof CartPhases, string> = {
    detail: '',
    shipping_and_payment: t('cart.continue_to.shipping_and_payment'),
    delivery: t('cart.continue_to.delivery'),
    summary: t('cart.continue_to.summary'),
}

const backToPhaseLabels: Record<keyof CartPhases, string> = {
    detail: t('cart.back_to.cart'),
    shipping_and_payment: t('cart.back_to.shipping_and_payment'),
    delivery: '',
    summary: '',
}

const form = useForm({
    schema: () => z.object({
        agree_terms_and_conditions: zBooleanRequired,
        gdpr: zBooleanRequired,
    }),
    initialData: () => ({
        agree_terms_and_conditions: false,
        gdpr: false,
    }),
    onSubmit: () => {},
})

const events = useEvents()
const formEl = ref<ComponentInstance<typeof CoreUiForm> | null>(null)

events.useOn('cart:submitting', () => {
    return formEl.value?.validate()
})

// fetch cart items
await fetchItems()
</script>

<style lang="scss" scoped>
.vut-l-cart {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 2.875rem 8rem;

    @include more-than(lg) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2.875rem 4rem;
    }

    @include more-than(xxl) {
        gap: 2.875rem 8rem;
    }
}

.vut-l-cart--fw .vut-l-cart__button {
    width: initial;
}

</style>
